import React, { FC } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import about from '../data/about';

const About: FC = () => {
  return (
    <Layout>
      <SEO title="About" />
      <div
        className="container box"
        style={{ backgroundColor: 'black', color: 'white' }}
      >
        <div className="columns is-vcentered">
          {typeof window !== 'undefined' && window.innerWidth <= 800 ? (
            <img src={about.images[4]} alt="Image" />
          ) : (
            <div className="column">
              <div className="columns is-multiline is-mobile is-centered">
                {about.images.map((image, idx) => (
                  <div key={idx} className={'column is-half'}>
                    <img src={image} alt="Image" />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="column ">
            <div className="has-text-centered py-5">
              <h1 className="title is-1" style={{ color: 'white' }}>
                {about.title}
              </h1>
            </div>
            {about.info.map((info, idx) => (
              <p className="content is-size-3" key={idx}>
                {info}
              </p>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
