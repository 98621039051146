export default {
  title: 'About Ai Soul',
  images: [
    '../../about/image_1.jpg',
    '../../about/image_2.jpg',
    '../../about/image_3.jpg',
    '../../about/image_4.jpg',
    '../../about/image_5.jpg',
    '../../about/image_6.jpg',
  ],
  info: [
    'You might ask, what makes “Ai Soul” different from other spa and beauty salons? “Ai Soul” is a place of empowerment, inspiration and attentiveness. ',
    'Our logo consists of an elegant woman that powerfully raises the main symbol of «Ai Soul» - shanyrak. This is a cultural relic, which signifies prosperity, respect, unity and hope🤍',
    'Kazakhstan is recognized as the homeland of sacred family traditions that are based on values like hospitality, care and sincerity. In the creation of the “Ai Soul” project, we carefully have combined foundational Kazakh traditions and modern innovative methods to provide you with an unforgettable experience and personalized beauty care',
  ],
};
